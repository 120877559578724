.home-page-container {
  padding-top: 16px;
  color: white;
}

.home-page-title {
  margin-top: 10px;
  font-family: "Courier New", Courier, monospace;
  font-size: 32pt;
}

.counter-label {
  color: #408800;
  font-variant-caps: small-caps;
  margin-bottom: 20px;
}

.modal-body > .welcome-popup-section-title {
  font-size: large;
  font-weight: bold;
}

.modal-body > .welcome-popup-section-body {
  font-size: small;
  margin: 10px;
}

.search-wrapper {
  display: flex;
  justify-content: center;
}

.search-btn {
  margin-left: 6px;
}

.main-prompt {
  background-color: white;
  width: 100%;
}

.main-prompt-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

.prompt-text {
  font-size: 32pt;
  font-family: "Courier New", Courier, monospace;
}

.main-line {
  font-size: x-large;
  font-family: "Courier New", Courier, monospace;
  height: 50px;
}

@media (max-width: 500px) {
  .main-prompt-container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .prompt-text {
    font-size: 22pt;
  }
  .main-line {
    font-size: large;
  }
}

.scroll-down-button {
  padding-bottom: 25px;
  height: 75px;
  cursor: pointer;
  animation: verticalBounce 1.25s infinite cubic-bezier(0.5, 0.05, 1, 0.5),
    partialFadeInOpacity 2s 1;
  font-family: "Courier New", Courier, monospace;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.vertical-bounce {
  animation: verticalBounce 1s cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes partialFadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes verticalBounce {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 0.6;
  }
  to {
    transform: translate3d(0, 10px, 0);
    opacity: 0.35;
  }
}
