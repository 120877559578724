.audio-controls-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  height: 60px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(41, 41, 41, 0.85);
  border-top: 2px solid rgb(141, 141, 141);
  color: white;

  opacity: 0;
  transform: translateY(100px);
  transition: all 0.5s ease;
}

.audio-controls-active {
  opacity: 1;
  transform: translateY(0);
}

.audio-controls-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Courier Prime", monospace;
  max-width: 700px;
}

.audio-control-slider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  width: 100%;
  font-size: small;
  margin-left: 8px;
}

.progress-slider {
  width: 100%;
  max-width: 500px;
}

.play-pause-button {
  width: 45px;
  cursor: pointer;
}
