.infinite-scroll-component > .row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 25%;
}

.infinite-scroll-component > .row > .col {
  padding-left: 0px;
  padding-right: 0px;
}

.freqviz {
  position: absolute;
  width: 22%;
  bottom: -35px;
  margin-left: 5%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
}

.freqviz-playing {
  opacity: 0.4;
}

.scroll-button {
  position: fixed;
  top: 5px;
  height: 20px;
  left: 94%;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  color: #0d6efd;

  /* Hide with opacity */
  opacity: 0;

  /* also add a translate effect */
  transform: translateY(-100px);

  /* and a transition */
  transition: all 0.5s ease;
}

.showBtn {
  opacity: 1;
  transform: translateY(0);
}

.cassette-actions {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  bottom: 1%;
  right: 4%;
}

.action-link {
  font-size: medium;
}

.cassette-container {
  position: relative;
  margin-bottom: 100px;
  max-width: 100%;
  color: rgb(58, 58, 58);
}

.scale-text-with-cassette {
  font-size: 2em;
}

.cassette-image {
  max-width: 100%;
  cursor: pointer;
}

.cassette-label-recording {
  position: absolute;
  top: 13%;
  left: 18%;
  pointer-events: none;
}

.cassette-label-created {
  position: absolute;
  top: 12.9%;
  left: 47%;
  pointer-events: none;
}

.cassette-label-desc {
  position: absolute;
  top: 23.75%;
  pointer-events: none;
  left: 20%;

  /* Truncation of description */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 65%;
}

.cassette-loading {
  position: absolute;
  bottom: 0;
  left: 5%;
  width: 50px;
  height: 50px;
  pointer-events: none;
}

.cassette-label-recording,
.cassette-label-desc,
.cassette-label-created {
  font-family: "Courier Prime", monospace;
}

/* Adjustments for incrementally smaller screens */

/* col-xl */
@media (max-width: 1200px) {
  .scale-text-with-cassette {
    font-size: 1.7em;
  }

  .action-link {
    font-size: medium;
  }

  .freqviz {
    bottom: -30px;
  }
}

/* col-lg */
@media (max-width: 992px) {
  .scale-text-with-cassette {
    font-size: 1.25em;
  }

  .scroll-button {
    left: 48%;
  }

  .cassette-label-desc {
    top: 23.5%;
  }

  .freqviz {
    bottom: -25px;
  }

  .cassette-actions {
    bottom: 0%;
    right: 3%;
  }

  .action-link {
    font-size: small;
  }

  .cassette-loading {
    bottom: 0;
    width: 35px;
    height: 35px;
  }
}

/* col-md */
@media (max-width: 768px) {
  .scale-text-with-cassette {
    font-size: 0.95em;
  }

  .scroll-button {
    left: 47%;
  }

  /* Small tweak for good vertical position */
  .freqviz {
    bottom: -20px;
  }

  .cassette-actions {
    bottom: -3%;
    right: 2%;
  }

  .action-link {
    font-size: smaller;
  }

  .cassette-loading {
    bottom: -2px;
    width: 25px;
    height: 25px;
  }
}

/* col-sm */
@media (max-width: 576px) {
  .scale-text-with-cassette {
    font-size: 2.75vw;
  }

  .cassette-label-desc {
    top: 23.5%;
  }

  .scroll-button {
    left: 45%;
  }

  .cassette-actions {
    bottom: -6%;
    right: 1%;
  }

  .action-link {
    font-size: x-small;
  }

  .cassette-loading {
    bottom: -8px;
    width: 20px;
    height: 20px;
  }
}
