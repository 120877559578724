.volume-details-container {
  margin-top: 24px;
  color: white;
}

.volume-title {
  margin-top: 24px;
  font-family: "Courier New", Courier, monospace;
}

.volume-description {
  margin-top: 10px;
  font-family: "Courier New", Courier, monospace;
}
